<template>
  <div id="app">
    <header class="container">
      <div class="logo">
        <img src="./assets/logo@2x.png" width="164" alt="Ultrafluid">
      </div>

      <ul>
        <li>Home</li>
        <li @click="scrollTo('about')">About</li>
        <li @click="scrollTo('portfolio')">Portfolio</li>
        <li @click="scrollTo('contact')">Contact</li>
      </ul>
    </header>

    <div class="slogan">
      <h1>Ultrafluid Venture</h1>
      <h3>Provide never-ending liquidity services</h3>
    </div>

    <div class="platform container">
        <p> One-stop liquidity service investment platform</p>
    </div>

    <ul class="feature container">
      <li>Quantitative Trading<br> Strategy <img src="./assets/f1.png" alt=""></li>
      <li>Cryptocurrency<br> Derivatives <img src="./assets/f2.png" alt=""></li>
      <li>Liquidity Solutions <img src="./assets/f3.png" alt=""></li>
    </ul>

    <div class="about container" id="about">
      <h1>About Us</h1>
      <dl>
        <dd>
          <img src="./assets/a2-1.png">
          <img src="./assets/a1-2.png">
          <b v-prlx="{custom: true, limit: { min: 0, max: 80 }}"><img src="./assets/a1-1.png"></b>
          <i v-prlx="{custom: true, limit: { min: 0, max: 80 }}">$ 100,000,000</i>
        </dd>
        <dt>
          <h2>Ultrafluid Venture contributes numerous transaction funds to digital markets</h2>
          <p>Since its inception, Ultrafluid Venture’s main business has been liquidity services.
            <br><br>
            It has traded billions of dollars in the digital market, providing project parties with optimal liquidity solutions and self-developed programmatic trading systems.</p>
        </dt>
      </dl>

      <dl>
        <dd>
          <img src="./assets/a2-1.png" v-prlx="{direction: 'x', reverse: true}">
          <img src="./assets/a2-2.png" v-prlx="{direction: 'x'}">
          <img src="./assets/a2-3.png" v-prlx="{direction: 'x', reverse: true}">
        </dd>
        <dt>
          <h2>Extensive experience in liquidity solutions</h2>
          <p>According to the characteristics of high volatility in the digital asset market, a variety of liquidity tools have been developed, including CEX liquidity services and DEX liquidity services.</p>
        </dt>
      </dl>

      <dl>
        <dd>
          <p v-prlx="{direction: 'x'}"><b><img src="./assets/BNB.png" alt=""></b><b><img src="./assets/kraken.png" alt=""></b><b><img src="./assets/Coinbase.png" alt=""></b></p>
          <p v-prlx="{direction: 'x', reverse: true}"><b><img src="./assets/MEXC.png" alt=""></b><b><img src="./assets/pancake.png" alt=""></b></p>
          <p v-prlx="{direction: 'x'}"><b><img src="./assets/aave.png" alt=""></b><b><img src="./assets/MDEX.png" alt=""></b><b><img src="./assets/DODO.png" alt=""></b></p>
          <p v-prlx="{direction: 'x', reverse: true}"><b><img src="./assets/huobi.png" alt=""></b><b><img src="./assets/dydx.png" alt=""></b><b><img src="./assets/DexTools.png" alt=""></b></p>
        </dd>
        <dt>
          <h2>Extensive investment experience in the field of liquidity</h2>
          <p>Ultrafluid Venture has provided liquidity on more than 60 trading platforms.
            <br><br>
              At present, the business has expanded to the primary market investment business, relying on liquidity services to provide high-quality post-investment services for projects.</p>
        </dt>
      </dl>
    </div>

    <div class="portfolio container" id="portfolio">
      <h1>Portfolio</h1>
      <p><b><img src="./assets/ethereum.png" alt=""></b><b><img src="./assets/BNB.png" alt=""></b><b><img src="./assets/solanr.png" alt=""></b><b><img src="./assets/EOS.png" alt=""></b></p>
      <p><b><img src="./assets/pancake.png" alt=""></b><b><img src="./assets/uniswap.png" alt=""></b><b><img src="./assets/aave.png" alt=""></b><b><img src="./assets/ftx.png" alt=""></b></p>
      <p><b><img src="./assets/bitfinex.png" alt=""></b><b><img src="./assets/dydx.png" alt=""></b><b><img src="./assets/kraken.png" alt=""></b><b><img src="./assets/Coinbase.png" alt=""></b></p>
      <p><b><img src="./assets/huobi.png" alt=""></b><b><img src="./assets/MDEX.png" alt=""></b><b><img src="./assets/DODO.png" alt=""></b><b><img src="./assets/Deribit.png" alt=""></b></p>
      <p><b><img src="./assets/MEXC.png" alt=""></b><b><img src="./assets/DexTools.png" alt=""></b><b><img src="./assets/Bitstamp.png" alt=""></b><b><img src="./assets/h20.png" alt=""></b></p>
    </div>

    <footer id="contact">
      <div class="social container">
        <img src="./assets/logo-w.png" alt="">
        <ul>
          <li><a href="mailto:hi@ultrafluid.vc" target="blank"><img src="./assets/mail.png" alt=""></a></li>
          <li><a href="https://twitter.com/UltrafluidVC" target="blank"><img src="./assets/twitter.png" alt=""></a></li>
          <!-- <li><a href="" target="blank"><img src="./assets/discord.png" alt=""></a></li> -->
          <!-- <li><a href="" target="blank"><img src="./assets/tele.png" alt=""></a></li> -->
        </ul>
      </div>
      <p>Copyright © Ultrafluid venture 2022. All Rights Reserved</p>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  methods: {
    scrollTo(id) {
      window.document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}
</script>

<style lang="less">
body {
  background: #000;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  position: relative;
  overflow-x: hidden;
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: 1000px;
    margin-left: -950px;
    background-image: url(./assets/bg-l.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 50%;
    height: 1000px;
    margin-left: 100px;
    background-image: url(./assets/bg-r.png), url(./assets/yanse.png);
    background-repeat: no-repeat;
    background-position: 0 0, left center;
    background-size: auto, 1920px;
    z-index: -10;
  }
 
}

.container {
  width: 1184px;
  margin: 0 auto;
}

header {
  margin-top: 24px !important;
  position: relative;
  ul {
    display: flex;
    position: absolute;
    right: 0;
    top: 20px;
    li {
      margin-left: 65px;
      cursor: pointer;
    }
  }
}

.slogan {
  text-align: center;
  margin: 210px 0;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  h1 {font-size: 76px; font-weight: bolder;}
  h3 {font-size: 28px; margin-top: 32px;}
}

.platform {
  height: 192px;
  line-height: 192px;
  text-align: center;
  font-size: 24px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: linear-gradient(130deg, #FF871C 0%, #4159DA 59%, #37BBAD 100%);
    border-radius: 24px;
    opacity: 0.22;
  }
}

.feature {
  display: flex;
  margin-top: 28px;
  justify-content: space-between;
  gap: 28px;
  li {
    flex: 1;
    height: 376px;
    background: rgba(43, 27, 78, 0.66);
    border-radius: 24px;
    font-size: 24px;
    padding: 40px;
    box-sizing: border-box;
    line-height: 1.4;
    background-position: bottom right;
    background-repeat: no-repeat;
    position: relative;
    img {
      position: absolute;
      right: 40px;
      bottom: 40px;
    }
    &:nth-child(1) {
      background-image: url(./assets/fbg1.png);
    }
    &:nth-child(2) {
      background-image: url(./assets/fbg2.png);
    }
    &:nth-child(3) {
      background-image: url(./assets/fbg3.png);
    }
  }
}

.about {
  padding-top: 160px;
  &>h1 {
    font-size: 44px;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 100px;
    &::after {
      content: '';
      display: block;
      width: 144px;
      height: 12px;
      margin: -10px auto 0;
      background: linear-gradient(130deg, #FF871C 0%, #4159DA 59%, #37BBAD 100%);
      border-radius: 6px;
      opacity: 0.4;
    }
  }
  dl {
    padding-bottom: 200px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: bottom center;
    dt {
      margin-right: 720px;
      h2 {
        font-size: 44px;
        font-weight: bolder;
      }
      p {font-size: 18px; line-height: 1.4;margin-top:20px}
    }
    dd {
      float: right;
      width: 700px;
    }

    &:nth-of-type(1) {
      background-image: url(./assets/a1-bg.png);
      dt {
        padding-top: 20px;
      }
      dd {
        position: relative;
        height: 600px;
        >img {
          position: absolute;
          top: 0; right: 0;
          &:nth-of-type(2) {
            top: 10%; left: 20%;
          }
        }
        >b {
          position: absolute;
          top: 20%; left: 20%;
          width: calc(var(--parallax-value) * 1%);
          overflow-x: hidden;
        }
        >i {
          position: absolute;
          top: 15%; right: 0;
          width: 158px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-weight: bolder;
          background: linear-gradient(130deg, #FF871C 0%, #4159DA 59%, #37BBAD 100%);
          border-radius: 4px;
          opacity: calc(var(--parallax-value) / 100);
        }
      }
    }

    &:nth-of-type(2) {
      background-image: url(./assets/a2-bg.png);
      margin-top: -100px;
      dt {
        width: 440px;
        margin-left: 720px;
        padding-top: 80px;
      }
      dd {
        float: left;
        position: relative;
        height: 500px;
        img {
          position: absolute;
          top: 0; right: 10%;
          &:nth-of-type(2) {
            top: 20%; left: 0;
          }
          &:nth-of-type(3) {
            top: 50%; left: 30%;
          }
        }
      }
    }

    &:nth-of-type(3) {
      padding-bottom: 0;
      dt {
        padding-top: 40px;
      }
      dd{
        background-image: url(./assets/a3-bg.png);
        background-repeat: no-repeat;
        background-position: top center;
        padding: 80px 0;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0; left: 0;
          width: 100%; height: 100%;
          box-shadow: 0 0 600px #000 inset;
          z-index: 1;
        }
        p {
          margin-bottom: 10px;
          &:nth-child(even) {
            margin-left: 120px;
          }
          b {
            display: inline-block;
            height: 60px;
            margin: 0 32px;
            img {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.portfolio {
  padding-top: 160px;
  background-repeat: no-repeat;
  background-image:url(./assets/pbg.png);
  background-position: center;
  &>h1 {
    font-size: 44px;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 150px;
    &::after {
      content: '';
      display: block;
      width: 144px;
      height: 12px;
      margin: -10px auto 0;
      background: linear-gradient(130deg, #FF871C 0%, #4159DA 59%, #37BBAD 100%);
      border-radius: 6px;
      opacity: 0.4;
    }
  }
  p {
    text-align: center;
    margin-bottom: 60px;
    b {
      display: inline-block;
      width: 23%;
    }
    img {
      vertical-align: middle;
    }
  }
}

footer {
  margin-top: 320px !important;
  div.social {
    position: relative;
    ul {
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      li {
        margin-left: 16px;
      }
    }
  }
  p {
    text-align: center;
    font-size: 14px;
    line-height: 10;
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import './assets/reset.css'
import VuePrlx from 'vue-prlx'

Vue.use(VuePrlx);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
